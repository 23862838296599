import {Component, EventEmitter, inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {HttpResponse} from '@angular/common/http';
import {concatMap, Observable, Subscription} from 'rxjs';
import {IconName} from '@fortawesome/fontawesome-svg-core';
import {Vehicle} from '../../../models/vehicle';
import {JobInformation} from '../../../models/job-information';
import {FnpFileType} from '../../../models/fnp-file';
import {EffectiveDateDto} from '../../../models/effective-date-dto';
import {JobInfoTableComponent} from './job-info-table/job-info-table.component';
import {JOB_INFORMATION_TYPE_ID, REQUESTED_JOB_INFORMATION_TYPE} from '../../../utils/enums/job-information';
import {MAX_REGISTRATION_NUMBER_LENGTH, MAX_VIN_LENGTH} from 'src/app/utils/other/constants';
import {getMessageFromError, prepareAndDownloadFileAction} from '../../../utils/other/utilities';
import {isAnyFilledValidator} from '../../../utils/validators/is-any-filled-validator';
import {VehicleService} from '../../../services/vehicle.service';
import {DialogService} from '../../../services/dialog.service';
import {FileService} from '../../../services/file.service';
import {JobInformationService} from '../../../services/job-information.service';
import {FnpLanguageService} from '../../../services/fnp-language.service';
import {FnpReportService} from '../../../services/fnp-report.service';
import {MarketService} from '../../../services/market.service';


@Component({
    selector: 'app-fnp',
    templateUrl: './fnp.component.html',
    styleUrls: ['./fnp.component.scss']
})
export class FnpComponent implements OnInit, OnDestroy {

    public vehicleFormGroup: FormGroup;
    public checkboxFormGroup: FormGroup;
    public vehicleFormError: string;
    public currentVehicle?: Vehicle;
    public effectiveDate?: Date;
    public printing = false;

    public serviceData?: JobInformation[] = [];
    public extendedServiceData?: JobInformation[] = [];
    public repairData?: JobInformation[] = [];
    public jobServiceErrMsg?: string;
    public jobMaintenanceErrMsg?: string;
    public jobRepairErrMsg?: string;

    public headers: string[];
    public handleSelections$: EventEmitter<'filter' | 'clear'> = new EventEmitter<'filter' | 'clear'>();
    public lastSearch?: 'vin' | 'regNumber';

    @ViewChild('serviceTableComponentRef')
    public serviceTableComponentRef: JobInfoTableComponent;
    @ViewChild('maintenanceTableComponentRef')
    public maintenanceTableComponentRef: JobInfoTableComponent;
    @ViewChild('repairTableComponentRef')
    public repairTableComponentRef: JobInfoTableComponent;

    private readonly _formBuilder: FormBuilder = inject(FormBuilder);
    private readonly _vehicleService: VehicleService = inject(VehicleService);
    private readonly _dialogService: DialogService = inject(DialogService);
    private readonly _fileService: FileService = inject(FileService);
    private readonly _jobInformationService: JobInformationService = inject(JobInformationService);
    private readonly _languageService: FnpLanguageService = inject(FnpLanguageService);
    private readonly _marketService: MarketService = inject(MarketService);
    private readonly _fnpReportService: FnpReportService = inject(FnpReportService);

    private langChange$?: Subscription;
    private marketChange$?: Subscription;
    private effectiveDate$?: Subscription;
    private readonly tableIds: string[] = ['serviceExpandable', 'maintenanceExpandable', 'repairExpandable'];


    private readonly EXCEL_NO_ITEMS_PLACEHOLDER = 'FNP.NO_ITEMS_TO_SHOW';
    private readonly EXCEL_SEARCH__PLACEHOLDER = 'FNP.SEARCH_FOR_JOB_INFORMATION';
    private readonly EXCEL_FILTERED__PLACEHOLDER = '';

    public ngOnInit(): void {
        this.initVehicleForm();
        this.initCheckbox();
        this.initJobTablePlaceholders();
        this.langChange$ = this._languageService.updateLanguage$.subscribe((lang) => this.reloadWithNewLang(lang));
        this.marketChange$ = this._marketService.stateUpdate.subscribe(() => {
            this.fetchEffectiveDate();
            this.resetPage();
        });
        if (!this.effectiveDate) this.fetchEffectiveDate();
    }

    public ngOnDestroy(): void {
        this.langChange$?.unsubscribe();
        this.marketChange$?.unsubscribe();
        this.effectiveDate$?.unsubscribe();
    }

    private initVehicleForm(): void {
        this.vehicleFormGroup = this._formBuilder.group({
            vin: ['', [
                Validators.pattern('^[A-Za-z0-9]+$')
            ]],
            registrationNumber: ['']
        }, {validators: isAnyFilledValidator()});
    }

    private initCheckbox(): void {
        //TODO check if validators are needed
        this.checkboxFormGroup = this._formBuilder.group({
            repair: [false, []],
            service: [false, []]
        });
    }

    private fetchEffectiveDate(): void {
        this.effectiveDate$ = this._fileService.getEffectiveDate().subscribe({
            next: (response) => this.effectiveDate = new Date(response?.effectiveDate),
            error: (error) => this.displayErrorModal(error)
        });
    }

    private initJobTablePlaceholders(): void {
        this.jobServiceErrMsg = 'FNP.SEARCH_FOR_JOB_INFORMATION';
        this.jobMaintenanceErrMsg = 'FNP.SEARCH_FOR_JOB_INFORMATION';
        this.jobRepairErrMsg = 'FNP.SEARCH_FOR_JOB_INFORMATION';
    }

    private reloadWithNewLang(lang: string): void {
        if (this.vehicleFormGroup?.valid && this.currentVehicle) {
            this.requestVehicleInfo(lang);
        }
        if (
            this.vehicleFormGroup?.valid && this.currentVehicle &&
            (this.serviceData?.length > 0 || this.extendedServiceData?.length > 0 || this.repairData?.length > 0)
        ) {
            this.requestJobInfo(lang);
        }
    }

    public onSubmitVehicle(): void {
        this.clearErrorMessages();
        this.clearData();
        this.resetView();
        this.checkboxFormGroup.reset();
        if (this.validateVinAndRegistrationNumber()) {
            this.requestVehicleInfo();
        }
    }

    private validateVinAndRegistrationNumber(): boolean {
        let valid = true;
        if (this.vinValue && this.registrationNumberValue) {
            this.vehicleFormError = 'FNP.ENTERED_BOTH_VIN_AND_REGISTRATION_NUMBER';
            valid = false;
        } else if (!this.registrationNumberValue && this.vinValue.length < 17) {
            this.vehicleFormError = 'FNP.VIN_MUST_CONTAIN_17_CHARACTERS';
            valid = false;
        }
        return valid;
    }

    private requestVehicleInfo(lang?: string): void {
        let vehicleSearch: Observable<Vehicle>;
        if (this.isSearchByVin()) {
            this.lastSearch = 'vin';
            vehicleSearch = this._vehicleService.getVehicleByVin(this.vinValue, lang);
        } else if (this.isSearchByRegistrationNumber()) {
            this.lastSearch = 'regNumber';
            vehicleSearch = this._vehicleService.getVehicleByRegistrationNumber(this.registrationNumberValue, lang);
        }
        vehicleSearch.subscribe({
            next: (response) => this.handleVehicleResponse(response),
            error: (error) => this.displayErrorModal(error)
        });
    }

    public onSubmitJobInfo(): void {
        this.clearErrorMessages();
        this.resetView();
        if (!this.currentVehicle) {
            this.displayWarningModal('FNP.SEARCH_FOR_VEHICLE_FIRST');
        } else if (this.serviceSearchValue || this.repairSearchValue) {
            [this.serviceData, this.extendedServiceData, this.repairData] = [[], [], []];
            this.requestJobInfo();
        } else {
            this.displayWarningModal('FNP.NO_CHECKBOX_SELECTED');
        }
    }

    private requestJobInfo(lang?: string): void {
        this.clearJobData();
        const jobInformationType = (this.serviceSearchValue && this.repairSearchValue) ?
            REQUESTED_JOB_INFORMATION_TYPE.Both : this.serviceSearchValue ?
                REQUESTED_JOB_INFORMATION_TYPE.Service : REQUESTED_JOB_INFORMATION_TYPE.Repair;
        this._jobInformationService.getServiceJobInformation(this.currentVehicle?.vin, jobInformationType, lang).subscribe({
            next: (response) => this.assignJobInformationToData(response),
            error: (error) => this.handleGetJobError(error)
        });
    }

    private handleGetJobError(error: string): void {
        this.assignNoRecordsMessageToAll();
        console.log('DEBUG: ', error);
    }

    private assignNoRecordsMessageToAll(): void {
        this.jobServiceErrMsg = 'FNP.NO_ITEMS_TO_SHOW';
        this.jobMaintenanceErrMsg = 'FNP.NO_ITEMS_TO_SHOW';
        this.jobRepairErrMsg = 'FNP.NO_ITEMS_TO_SHOW';
    }

    private handleVehicleResponse(vehicle: Vehicle): void {
        const escapedPropertyName = ['bodyStyle', 'registrationNumber', 'vin'];
        const filledProperties = Object.entries(vehicle).filter((entry) => escapedPropertyName.includes(entry[0]) || entry[1]).length;
        if (filledProperties <= 3) {
            this.setNoDataFoundErrorText();
        } else {
            if (filledProperties < Object.values(vehicle).length) {
                this.setUnrecognisedErrorText();
            }
            this.currentVehicle = vehicle;
        }
    }

    private setNoDataFoundErrorText() {
        if (this.isSearchByVin()) {
            this.vehicleFormError = 'FNP.NO_DATA_FOUND_FOR_VIN';
        } else if (this.isSearchByRegistrationNumber()) {
            this.vehicleFormError = 'FNP.NO_DATA_FOUND_FOR_REGISTRATION_NUMBER';
        }
    }

    private setUnrecognisedErrorText() {
        if (this.isSearchByVin()) {
            this.vehicleFormError = 'FNP.ENTERED_VIN_NOT_RECOGNISED';
        } else if (this.isSearchByRegistrationNumber()) {
            this.vehicleFormError = 'FNP.NO_DATA_FOUND_FOR_REGISTRATION_NUMBER';
        }
    }

    public isSearchByVin(): boolean {
        return this.vinValue && !this.registrationNumberValue;
    }

    public isSearchByRegistrationNumber(): boolean {
        return this.registrationNumberValue && !this.vinValue;
    }

    private showErrorFromPossibleBlob(error: any) {
        const errPromise: Promise<string> = (error.error as Blob).text();
        if (errPromise) {
            errPromise.then((finalTxt) => this.displayErrorModal(finalTxt));
        } else {
            this.displayErrorModal(error);
        }
    }

    private displayErrorModal(errorMessage: string): void {
        const dialogContent = this._dialogService.getNewDialogContent(
            'error',
            null,
            null,
            `ERROR.${getMessageFromError(errorMessage)}`);
        this._dialogService.openInfoDialog(dialogContent, true);
    }

    private displayWarningModal(warningMessage: string): void {
        const dialogContent = this._dialogService.getNewDialogContent('warning', null, null, warningMessage);
        this._dialogService.openInfoDialog(dialogContent, true);
    }

    public downloadFile(fileType: FnpFileType.SERVICE | FnpFileType.REPAIR): void {
        let effectiveDate: EffectiveDateDto;
        const observable$: Observable<HttpResponse<ArrayBuffer>> = fileType === FnpFileType.SERVICE ?
            this._fileService.getActiveServiceFile() : this._fileService.getActiveRepairFile();
        this._fileService.getEffectiveDate().pipe(concatMap((ed: EffectiveDateDto) => {
            effectiveDate = ed;
            return observable$;
        }))
            .subscribe({
                next: (response) => prepareAndDownloadFileAction(response.body, this.buildFileName(effectiveDate, fileType), 'xls/xlsx'),
                error: (error) => this.showErrorFromPossibleBlob(error)
            });
    }

    public downloadRepairFile(): void {
        this.downloadFile(FnpFileType.REPAIR);
    }

    public downloadServiceFile(): void {
        this.downloadFile(FnpFileType.SERVICE);
    }

    private buildFileName(effectiveDate: EffectiveDateDto, fileType: FnpFileType): string {
        const dateObj = new Date(effectiveDate.effectiveDate);
        const fileName = fileType === FnpFileType.REPAIR ? this._marketService.repairFileName : this._marketService.serviceFileName;
        return `${fileName}-${dateObj.getMonth() + 1}-${dateObj.getFullYear()}.xlsx`;
    }

    private assignJobInformationToData(jobInformation: JobInformation[]): void {
        const uncategorizedJobInfos: JobInformation[] = [];
        let availableRegionalPrices = 1;
        jobInformation.forEach(jobInfo => {
            jobInfo.jobDescription = jobInfo.jobDescription.split(',').join(' ');
            if (availableRegionalPrices < 3 && jobInfo.region3Price) {
                availableRegionalPrices = 3;
            } else if (availableRegionalPrices < 2 && jobInfo.region2Price) {
                availableRegionalPrices = 2;
            }
            this.assignJobInfoToDataOrUncategorized(jobInfo, uncategorizedJobInfos);
        });
        this.assignHeaders(availableRegionalPrices);
        this.setErrorMessages();
    }

    private assignJobInfoToDataOrUncategorized(jobInfo: JobInformation, uncategorizedJobInfos: JobInformation[]): void {
        switch (jobInfo.typeId) {
            case JOB_INFORMATION_TYPE_ID.SERVICE:
                this.serviceData.push(jobInfo);
                break;
            case JOB_INFORMATION_TYPE_ID.REPAIR:
                this.repairData.push(jobInfo);
                break;
            case JOB_INFORMATION_TYPE_ID.EXTENDED_SERVICE:
                this.extendedServiceData.push(jobInfo);
                break;
            default:
                uncategorizedJobInfos.push(jobInfo);
                break;
        }
    }

    private setErrorMessages(): void {
        if (this.serviceSearchValue) {
            if (this.serviceData.length === 0) {
                this.jobServiceErrMsg = 'FNP.NO_ITEMS_TO_SHOW';
            } else {
                delete this.jobServiceErrMsg;
            }
        }
        if (this.serviceSearchValue) {
            if (this.extendedServiceData.length === 0) {
                this.jobMaintenanceErrMsg = 'FNP.NO_ITEMS_TO_SHOW';
            } else {
                delete this.jobMaintenanceErrMsg;
            }
        }
        if (this.repairSearchValue) {
            if (this.repairData.length === 0) {
                this.jobRepairErrMsg = 'FNP.NO_ITEMS_TO_SHOW';
            } else {
                delete this.jobRepairErrMsg;
            }
        }
    }

    private assignHeaders(availableRegionalPrices: number): void {
        this.headers = Object.assign([], []);
        switch (availableRegionalPrices) {
            case 1:
                this.headers.push('FNP.PRICE');
                break;
            case 2:
                this.headers.push(...['FNP.REGION_1', 'FNP.REGION_2']);
                break;
            case 3:
                this.headers.push(...['FNP.REGION_1', 'FNP.REGION_2', 'FNP.REGION_3']);
                break;
            default:
                console.log('DEBUG: No prices detected!');
        }
    }

    public toggleVisibility(divId: string): void {
        const div = document.getElementById(divId);
        div.style.display = div.style.display === 'none' ? 'flex' : 'none';
    }

    public getArrowClass(divId: string): IconName {
        return document.getElementById(divId)?.style?.display === 'none' ? 'chevron-up' : 'chevron-down';
    }

    public expandAllSections(): void {
        this.tableIds.forEach(tableId => document.getElementById(tableId).style.display = 'flex');
    }

    public resetPage(): void {
        this.clearErrorMessages();
        this.clearForms();
        this.clearData();
        this.resetView();
    }

    private clearErrorMessages(): void {
        delete this.vehicleFormError;
        this.initJobTablePlaceholders();
    }

    private clearForms(): void {
        this.vehicleFormGroup.reset();
        this.checkboxFormGroup.reset();
    }

    private clearJobData(): void {
        [this.serviceData, this.repairData, this.extendedServiceData] = [[], [], []];
    }

    private clearData(): void {
        this.clearJobData();
        delete this.currentVehicle;
    }

    private resetView(): void {
        this.handleSelections$.next('clear');
        this.expandAllSections();
    }

    public filterSelected(): void {
        if (this.isAnySelected()) {
            this.expandAllSections();
            this.handleSelections$.next('filter');
            [
                [this.serviceSearchValue, this.jobServiceErrMsg],
                [this.serviceSearchValue, this.jobMaintenanceErrMsg],
                [this.repairSearchValue, this.jobRepairErrMsg]
            ].filter(tuple => tuple[0])
                .forEach(filteredTuple => delete filteredTuple[1]);
        } else {
            this.displayWarningModal('FNP.NO_RECORDS_SELECTED');
        }
    }

    public isAnySelected(): boolean {
        return this.serviceTableComponentRef.isAnyRecordSelected()
            || this.maintenanceTableComponentRef.isAnyRecordSelected()
            || this.repairTableComponentRef.isAnyRecordSelected();
    }

    public print(): void {
        if (this.jobTablesEmpty()) {
            this.displayWarningModal('FNP.NO_RECORDS_SELECTED');
            return;
        }
        this.printing = true;
        setTimeout(() => {
            document.getElementById('fnp-container').style.display = 'none';
            document.getElementById('fnp-print-container').style.display = 'flex';
            window.print();
        }, 0);

        window.onafterprint = () => {
            document.getElementById('fnp-print-container').style.display = 'none';
            document.getElementById('fnp-container').style.display = 'flex';
            this.printing = false;
        };
    }

    private jobTablesEmpty(): boolean {
        return this.serviceData.length === 0 && this.extendedServiceData.length === 0 && this.repairData.length === 0;
    }

    public get vinValue(): string {
        return this.vehicleFormGroup.get('vin')?.value ?? '';
    }

    public get registrationNumberValue(): string {
        return this.vehicleFormGroup.get('registrationNumber')?.value ?? '';
    }

    public get maxVinLength(): number {
        return MAX_VIN_LENGTH;
    }

    public get maxRegistrationNumberLength(): number {
        return MAX_REGISTRATION_NUMBER_LENGTH;
    }

    public get serviceSearchValue(): boolean {
        return this.checkboxFormGroup.get('service').value;
    }

    public get repairSearchValue(): boolean {
        return this.checkboxFormGroup.get('repair').value;
    }

    public exportToExcel(): void {
        if (this.jobTablesEmpty()) {
            this.displayWarningModal('FNP.NO_RECORDS_SELECTED');
            return;
        }
        const placeholders = [];
        this.setPlaceholders(placeholders);
        this._fnpReportService.exportFnpDataToExcel(this.currentVehicle, this.serviceTableComponentRef.data,
            this.maintenanceTableComponentRef.data, this.repairTableComponentRef.data, placeholders, this.lastSearch === 'regNumber');
    }

    private setPlaceholders(placeholders: string[]): void {
        placeholders[0] = !this.serviceSearchValue ? this.EXCEL_SEARCH__PLACEHOLDER : this.serviceData.length === 0 ? this.EXCEL_NO_ITEMS_PLACEHOLDER : this.EXCEL_FILTERED__PLACEHOLDER;
        placeholders[1] = !this.serviceSearchValue ? this.EXCEL_SEARCH__PLACEHOLDER : this.extendedServiceData.length === 0 ? this.EXCEL_NO_ITEMS_PLACEHOLDER : this.EXCEL_FILTERED__PLACEHOLDER;
        placeholders[2] = !this.repairSearchValue ? this.EXCEL_SEARCH__PLACEHOLDER : this.repairData.length === 0 ? this.EXCEL_NO_ITEMS_PLACEHOLDER : this.EXCEL_FILTERED__PLACEHOLDER;
    }

}
