import {inject, Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {getAvailableLanguages} from '../../assets/i18n/available-languages';
import {BehaviorSubject, Observable} from 'rxjs';
import {StorageService} from './storage.service';
import {MarketService} from './market.service';
import {AuthService} from './auth.service';

@Injectable({
    providedIn: 'root'
})
export class FnpLanguageService {

    public updateLanguage$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

    private readonly CACHED_LANGUAGE = 'CACHED_LANGUAGE';
    private _translateService: TranslateService = inject(TranslateService);
    private _storageService: StorageService = inject(StorageService);
    private _marketService: MarketService = inject(MarketService);
    private _authService: AuthService = inject(AuthService);

    public translate(key: string): string {
        return key! ? this._translateService.instant(key) : key;
    }

    public use(langCode: string): Observable<any> {
        const validLang: string = getAvailableLanguages(this._authService.isCurrentlyLogged() ? this._marketService.currentMarket?.shortname : null).find(lang => lang.code?.toLowerCase().startsWith(langCode?.toLowerCase()))?.code
            ?? this._translateService.getDefaultLang();
        this.updateLanguage$.next(validLang);
        this._storageService.setStored(this.CACHED_LANGUAGE, langCode);
        return this._translateService.use(validLang);
    }

    public get currentLang(): string {
        return this._translateService.currentLang ?? this.cachedLanguage ?? this._translateService.getDefaultLang();
    }

    public get cachedLanguage(): string {
        return this._storageService.getStored(this.CACHED_LANGUAGE);
    }
}
