import {Component, inject, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {BehaviorSubject} from 'rxjs';
import {Market} from '../../models/market';
import {AuthService} from '../../services/auth.service';
import {MarketService} from '../../services/market.service';
import {FnpLanguageService} from '../../services/fnp-language.service';
import {RouteService} from '../../services/route.service';
import {AVAILABLE_MARKETS} from '../../../config/markets/available-markets';
import {getAvailableLanguages} from '../../../assets/i18n/available-languages';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ROLE} from '../../utils/enums/role';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

    public readonly availableMarkets: Market[] = AVAILABLE_MARKETS;
    public marketForm: FormGroup;

    public availableLanguages: { code: string, description: string }[];
    public languageForm: FormGroup;
    private previousLang: string;

    private readonly _authService = inject(AuthService);
    private readonly _router = inject(Router);
    private readonly _languageService: FnpLanguageService = inject(FnpLanguageService);
    private readonly _routeService: RouteService = inject(RouteService);
    private readonly _marketService: MarketService = inject(MarketService);
    private readonly _formBuilder: FormBuilder = inject(FormBuilder);

    constructor() {
        this.availableLanguages = getAvailableLanguages(this._authService.isCurrentlyLogged() ? this._marketService.currentMarket.shortname: null);
    }

    public ngOnInit(): void {
        this.setLanguage();
        this.setMarket();
    }

    private setLanguage(): void {
        if (!this.languageForm) {
            this.languageForm = this._formBuilder.group({
                language: [null, [Validators.required]]
            });
        }
        const cachedLang =
            this._routeService.isValidReloaded()
                ? (this._languageService.cachedLanguage ?? this._authService.keycloakLanguage)
                : (this._authService.keycloakLanguage ?? this._languageService.currentLang);

        this._languageService.updateLanguage$.subscribe({
            next: (langCode) => {
                this.previousLang = this.languageControl.value;
                this.languageControl.patchValue(langCode);
            },
            error: (e) => console.log(e)
        });
        if (cachedLang !== this.languageControl.value) {
            this._languageService.use(cachedLang);
        }
    }

    private setMarket(): void {
        this.marketForm = this._formBuilder.group({
            market: [this._marketService.currentMarket.id, [Validators.required]]
        });
        this._marketService.stateUpdate.subscribe({
            next: () => {
                this.marketControl.patchValue(this._marketService.currentMarket.id);
                Promise.resolve().then(() => this.refreshLanguages());
            }
        });
    }

    private refreshLanguages(): void {
        const tempAvailableLanguages = getAvailableLanguages(this._authService.isCurrentlyLogged() ? this._marketService.currentMarket.shortname: null);
        const isCurrentLanguageAvailable = tempAvailableLanguages.find(lang => lang.code === this._languageService.currentLang);
        this.availableLanguages = tempAvailableLanguages;
        if (!isCurrentLanguageAvailable) this._languageService.use(this.availableLanguages[0].code);
    }

    public submitLanguage(): void {
        this._languageService.use(this.languageControl.value).subscribe({
            next: () => this.previousLang = this.languageControl.value,
            error: () => this.languageControl.patchValue(this.previousLang)
        });
    }

    public submitMarket(): void {
        this._marketService.setMarketById(this.marketControl.value);
    }

    public logout(): void {
        this._router.navigate(['logout']);
    }

    public get loggedSubject(): BehaviorSubject<boolean> {
        return this._authService.isLogged$;
    }

    public get languageControl(): AbstractControl {
        return this.languageForm.get('language');
    }

    public get marketControl(): AbstractControl {
        return this.marketForm.get('market');
    }

    public get isAdminLogged(): boolean {
        return this._authService.isCurrentlyLogged() && this._authService.userRole === ROLE.ADMIN;
    }

}
