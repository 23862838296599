import {Component, inject, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {UserService} from '../../../services/user.service';
import {User} from '../../../models/user';
import {PasswordResetDto} from '../../../models/password-reset-dto';
import {asyncPasswordValidator} from '../../../utils/validators/async-password-validator';
import {DialogService} from '../../../services/dialog.service';
import {AuthService} from '../../../services/auth.service';
import {getMessageFromError} from '../../../utils/other/utilities';
import {FnpLanguageService} from '../../../services/fnp-language.service';

@Component({
    selector: 'app-my-details',
    templateUrl: './my-details.component.html',
    styleUrls: ['./my-details.component.scss']
})
export class MyDetailsComponent implements OnInit {

    public isEditActive = false;
    public editMode: 'user' | 'password' = 'user';
    public userFormGroup!: FormGroup;
    public passwordFormGroup!: FormGroup;
    public user: User;

    private _formBuilder: FormBuilder = inject(FormBuilder);
    private _authService: AuthService = inject(AuthService);
    private _userService: UserService = inject(UserService);
    private _dialogService: DialogService = inject(DialogService);
    private _languageService: FnpLanguageService = inject(FnpLanguageService);

    public ngOnInit(): void {
        this.getLoggedUser();

    }

    private getLoggedUser(): void {
        if (this._authService.loggedUser) {
            this.user = this._authService.loggedUser;
            this.initFormGroup();
        } else {
            this._userService.getUserByEmail(this._authService.userEmail).subscribe({
                next: (response) => {
                    this.user = response;
                    this.initFormGroup();
                },
                error: (e) => this.displayErrorModal(e)
            });
        }
    }

    private initFormGroup(): void {
        this.userFormGroup = this._formBuilder.group({
            finCode: [this.user.finCode, []],
            companyName: [this.user.companyName, []],
            email: [this.user.email, []],
            contactName: [this.user.contactName, [Validators.required]]
        });
        this.passwordFormGroup = this._formBuilder.group({
                currentPassword: ['', [Validators.required]],
                newPassword: ['', [Validators.required]],
                confirmPassword: ['', [Validators.required]]
            },
            {validators: asyncPasswordValidator()});
    }

    public setPasswordEdit(): void {
        this.editMode = 'password';
    }

    public setUserEdit(): void {
        this.passwordFormGroup.reset();
        this.editMode = 'user';
    }

    public toggleEdit(): void {
        if (this.isEditActive) this.resetForm();
        this.isEditActive = !this.isEditActive;
    }

    private resetForm(): void {
        this.contactNameCtrl.patchValue(this.user.contactName);
        this.currentPasswordCtrl.patchValue('');
        this.newPasswordCtrl.patchValue('');
        this.confirmPasswordCtrl.patchValue('');
    }

    public submitPassword(): void {
        const passwordResetDto: PasswordResetDto = {
            currentPassword: this.currentPasswordCtrl.value,
            newPassword: this.newPasswordCtrl.value,
            locale: this._languageService.currentLang
        };
        this._userService.updatePassword(this._authService.userName, passwordResetDto)
            .subscribe({
                next: () => this.displaySuccessPasswordUpdate(),
                error: (error) => {
                    this.displayErrorModal(error);
                },
                complete: () => this.toggleEdit()
            });
    }

    private displaySuccessPasswordUpdate(): void {
        this.displaySuccessModal('PASSWORD_CHANGED_SUCCESSFULLY');
        this.editMode = 'user';
    }

    public submitUser(): void {
        this.user.contactName = this.contactNameCtrl.value;
        this._userService.patchUser(this.user.email, this.contactNameCtrl.value)
            .subscribe({
                    next: () => this.handleSuccessfulUserUpdate(),
                    error: (error) => this.handleFailedUserUpdate(error),
                    complete: () => this.toggleEdit()
                }
            );
    }

    private handleSuccessfulUserUpdate(): void {
        this.user.contactName = this.contactNameCtrl.value;
        this._authService.loggedUserContactName = this.contactNameCtrl.value;
        this.displaySuccessUserUpdate();
    }

    private handleFailedUserUpdate(error: any): void {
        this.contactNameCtrl.patchValue(this._authService.loggedUserContactName);
        this.user.contactName = this.contactNameCtrl.value;
        this.displayErrorModal(error);
    }

    private displaySuccessUserUpdate(): void {
        this.displaySuccessModal('USER_UPDATED_SUCCESSFULLY');
    }

    private displayErrorModal(error: any): void {
        let errMessage: string;
        if (error.error && typeof error.error === 'string') {
            errMessage = (error?.error as string).startsWith('EC') ? `ERROR.${getMessageFromError(error)}` : error?.error;
        } else {
            errMessage = 'ERROR.EC0000';
        }

        const dialogContent = this._dialogService.getNewDialogContent(
            'error',
            'GLOBAL.ERROR_HAS_OCCURRED',
            null,
            errMessage
        );
        this._dialogService.openInfoDialog(dialogContent);
    }

    private displaySuccessModal(message: string): void {
        const dialogContent = this._dialogService.getNewDialogContent(
            'success',
            null,
            null,
            `MY_DETAILS.${message}`
        );
        this._dialogService.openInfoDialog(dialogContent);
    }

    public get contactNameCtrl(): AbstractControl {
        return this.userFormGroup.get('contactName');
    }

    public get currentPasswordCtrl(): AbstractControl {
        return this.passwordFormGroup.get('currentPassword');
    }

    public get newPasswordCtrl(): AbstractControl {
        return this.passwordFormGroup.get('newPassword');
    }

    public get confirmPasswordCtrl(): AbstractControl {
        return this.passwordFormGroup.get('confirmPassword');
    }

}
