import {MarketLanguages, NgxLanguage} from '../../app/models/translate';

export const AVAILABLE_LANGUAGES: NgxLanguage[] = [
    {code: 'en', description: 'LANGUAGE.ENGLISH'},
    {code: 'en_uk', description: 'LANGUAGE.ENGLISH'},
    {code: 'de', description: 'LANGUAGE.GERMAN'}
];

// TODO add before ACH going live
// {code: 'it', description: 'LANGUAGE.ITALIAN'},
//     {code: 'fr', description: 'LANGUAGE.FRENCH'}

export const MARKET_SPECIFIC_LANGUAGES: MarketLanguages[] = [
    {marketCode: 'uk', languageCodes: ['en_uk']},
    {marketCode: 'de', languageCodes: ['de','en']}
];

// `_` marks different variations of the same language.
// By default, we want to return single language.
export function getAvailableLanguages(marketCode?: string, admin?: boolean): NgxLanguage[] {
    if (admin) {
        return AVAILABLE_LANGUAGES;
    }
    for (const msl of MARKET_SPECIFIC_LANGUAGES) {
        if (msl.marketCode.toLowerCase() === marketCode?.toLowerCase()) {
            return AVAILABLE_LANGUAGES.filter(lang => msl.languageCodes.includes(lang.code.toLowerCase()));
        }
    }
    return AVAILABLE_LANGUAGES.filter(lang => lang.code.toLowerCase() !== 'en');
}
